<template>
  <div class="login-wrap-login">
    <Top :shouback="true" />
    <div class="list-mid">
      <div class="anniu">
        <div class="anniuitem" :class="anniuchose == 0 ? 'chose_anniuitem' : ''" @click="clickanniu(0)"
          v-if="showtab==1">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div class="anniu-wenzi">B-MZC01 等离子体空气消毒机</div>
        </div>
        <div class="anniuitem" :class="anniuchose == 1 ? 'chose_anniuitem' : ''" style="margin-left: 26px"
          @click="clickanniu(1)" v-if="showtab==1">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div class="anniu-wenzi">Y-MZH20 水离子空气消毒机</div>
        </div>
        <div class="anniuitem" :class="anniuchose == 2 ? 'chose_anniuitem' : ''" style="margin-left: 26px"
          @click="clickanniu(2)" v-if="showtab1==2">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div class="anniu-wenzi">防疫通数字哨兵</div>
        </div>
        <div class="anniuitem" :class="anniuchose == 3 ? 'chose_anniuitem' : ''" style="margin-left: 26px"
          @click="clickanniu(3)" v-if="showtab2==3">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div class="anniu-wenzi">防疫机器人</div>
        </div>
        <div class="anniuitem" :class="anniuchose == 4 ? 'chose_anniuitem' : ''" style="margin-left: 26px"
          @click="clickanniu(4)" v-if="showtab3==5">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <div class="anniu-wenzi">天青脉冲等离子</div>
        </div>
      </div>
      <div class="list-table">
        <div class="tableseach">
          <div class="se_input_wai">
            <div class="border_corner border_corner_left_top"></div>
            <div class="border_corner border_corner_right_top"></div>
            <div class="border_corner border_corner_left_bottom"></div>
            <div class="border_corner border_corner_right_bottom"></div>
            <input class="se_input" type="text" v-model="inputvalue" :placeholder="searchName" />
          </div>
          <button class="se_button" @click="search">搜索</button>
        </div>
        <div class="tablediv">
          <el-table v-if="type===0" :data="tableData" style="width: 100%" @selection-change="checkboxChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" width="80px" type="index" label="序号">
            </el-table-column>
            <el-table-column align="center" prop="deviceSn" label="设备编号"></el-table-column>
            <el-table-column align="center" prop="name" label="设备名称">
              <template #default="scope">
                <el-input show-word-limit maxlength="6" placeholder="请输入设备名称" :suffix-icon="iconname"
                  @blur="changename(scope.row)" v-model="scope.row.name"></el-input>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="userName" label="绑定用户">
            </el-table-column> -->
            <el-table-column align="center" prop="userPhone" label="绑定手机号">
            </el-table-column>
            <el-table-column align="center" prop="createTime" label="绑定时间">
            </el-table-column>
            <!-- <el-table-column align="center" prop="workingTime" label="使用时间(h)">
            </el-table-column> -->
            <el-table-column align="center" prop="statuszhuangtai" label="当前状态">
              <template #default="scope">
                <div :class="
                    scope.row.statuszhuangtai == '在线'
                      ? 'sb_shiyongz'
                      : 'sb_weishiyong'
                  ">
                  {{ scope.row.statuszhuangtai }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="caozuo" label="操作" v-if="type===0&&clientId!=100" width="500">
              <template #default="scope">
                <div style="display: flex; justify-content: center" v-if="scope.row.dataType == 0">
                  <div :class="
                      scope.row.status == 1 ? 'sb_shiyongz' : 'sb_weishiyong'
                    " @click="kaiguanji(scope.row)" class="kaiguanji">
                    {{ scope.row.caozuo }}
                  </div>
                  <div class="dingshibtn">

                    <el-button @click="chooseInterval( scope.row.deviceId)">定时</el-button>

                  </div>

                </div>
              </template>
            </el-table-column>

          </el-table>
          <el-table v-if="type===1" :data="tableData" style="width: 100%" @selection-change="checkboxChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" width="80px" type="index" label="序号">
            </el-table-column>
            <el-table-column align="center" prop="deviceId" label="设备编号"></el-table-column>
            <el-table-column align="center" prop="name" label="设备名称">
              <template #default="scope">
                <el-input show-word-limit placeholder="请输入设备名称" :suffix-icon="iconname" @blur="changename(scope.row)"
                  v-model="scope.row.name"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="userName" label="绑定用户">
            </el-table-column>
            <el-table-column align="center" prop="userPhone" label="绑定手机号">
            </el-table-column>
            <el-table-column align="center" prop="createTime" label="绑定时间">
            </el-table-column>
            <el-table-column align="center" prop="workingTime" label="使用时间(h)">
            </el-table-column>
            <el-table-column align="center" prop="statuszhuangtai" label="当前状态">
              <template #default="scope">
                <div :class="
                    scope.row.statuszhuangtai == '在线'
                      ? 'sb_shiyongz'
                      : 'sb_weishiyong'
                  ">
                  {{ scope.row.statuszhuangtai }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="caozuo" label="操作" v-if="type===0&&clientId!=100">
              <template #default="scope">
                <div style="display: flex; justify-content: center" v-if="scope.row.dataType == 0">
                  <div :class="
                      scope.row.status == 1 ? 'sb_shiyongz' : 'sb_weishiyong'
                    " @click="kaiguanji(scope.row)" class="kaiguanji">
                    {{ scope.row.caozuo }}
                  </div>
                </div>
              </template>
            </el-table-column>

          </el-table>
          <el-table v-if="type===2" :data="tableData" style="width: 100%" @selection-change="checkboxChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" width="100px" type="index" label="序号">
            </el-table-column>

            <el-table-column align="center" width="1400" prop="deviceSn" label="设备编号"></el-table-column>

            <el-table-column align="center" prop="createTime" label="绑定时间">
            </el-table-column>

          </el-table>
          <el-table v-if="type===3" :data="tableData" style="width: 100%" @selection-change="checkboxChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" width="100px" type="index" label="序号">
            </el-table-column>
            <el-table-column align="center" prop="deviceName" label="设备名称">
              <template #default="scope">
                <el-input class="robot" width="50" show-word-limit placeholder="请输入设备名称" :suffix-icon="iconname"
                  @blur="changename(scope.row)" v-model="scope.row.deviceName"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" width="500" prop="deviceId" label="设备编号"></el-table-column>
            <el-table-column align="center" prop="createTime" label="绑定时间">
            </el-table-column>
            <!-- <el-table-column align="center" prop="userTime" label="使用时间(h)">
            </el-table-column> -->
            <!-- <el-table-column
              align="center"
              prop="status"
              label="当前状态"
            >
              <template #default="scope">
                <div
                  :class="
                    scope.row.status == '使用中'
                      ? 'sb_shiyongz'
                      : 'sb_weishiyong'
                  "
                >
                  {{ scope.row.status }}
                </div>
              </template>
            </el-table-column> -->
          </el-table>
          <el-table v-if="type===4" :data="tableData" style="width: 100%" @selection-change="checkboxChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" width="80px" type="index" label="序号">
            </el-table-column>
            <el-table-column align="center" prop="deviceSn" label="设备编号"></el-table-column>
            <el-table-column align="center" prop="name" label="设备名称">
              <template #default="scope">
                <el-input show-word-limit maxlength="6" placeholder="请输入设备名称" :suffix-icon="iconname"
                  @blur="changename(scope.row)" v-model="scope.row.name"></el-input>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="userName" label="绑定用户">
            </el-table-column> -->
            <el-table-column align="center" prop="userPhone" label="绑定手机号">
            </el-table-column>
            <el-table-column align="center" prop="createTime" label="绑定时间">
            </el-table-column>
            <!-- <el-table-column align="center" prop="workingTime" label="使用时间(h)">
            </el-table-column> -->
            <el-table-column align="center" prop="status" label="当前状态">
              <template #default="scope">
                <div :class="
                    scope.row.status == '在线'
                      ? 'sb_shiyongz'
                      : 'sb_weishiyong'
                  ">
                  {{ scope.row.status }}
                </div>
              </template>
            </el-table-column>

          </el-table>
          <div class="pagenum">
            <!-- 分页 -->

            <el-pagination layout="prev, pager, next,total" :total="totalpage" :page-size="pagesize"
              v-model:current-page="currentpage" default-current-page="1" @current-change="currentchange">
            </el-pagination>

            <!-- excel导出按钮 -->
            <!-- clientId==100福瑞士  不要导出 -->
            <el-button type="primary" @click="exportDataBtn('choose')"
              v-show="tableData.length&&type !== 3&&clientId!=100"><img src="~@/assets/img/数据导出.png"
                alt="" /><span>导出选中数据</span> </el-button>
            <el-button type="primary" @click="exportDataBtn('all')"
              v-show="tableData.length&&type !== 3&&clientId!=100"><img src="~@/assets/img/数据导出.png"
                alt="" /><span>导出全部数据</span>
            </el-button>

          </div>
          <!-- 数据导出弹层 -->
          <el-dialog title="请选择您想导出的数据的时间" v-model="showchoose">
            <div class="demo-date-picker" v-loading="loading" element-loading-text="导出中..">
              <div class="block">
                <el-date-picker v-model="value2" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" align="right" format="YYYY年MM月DD日" @change="dateChange"
                  :disabled-date="disabledDate" :size="size">
                </el-date-picker>
              </div>
            </div>
            <el-row :gutter="10" type="flex" justify="center">
              <el-col :span="7">
                <el-button @click="exportBtn"> 导出</el-button>
              </el-col>
              <el-col :span="7">
                <el-button @click="cancelBtn">取消</el-button>
              </el-col>
            </el-row>
          </el-dialog>
          <!-- 定时弹框 -->
          <el-dialog :title="titled" v-model="showdingshi" @close="closedignshi">
            <el-radio-group v-model="dingshi.cycle" @change="statuschange" class="dingshicycle">
              <el-radio label="0" class="elradio">每日</el-radio>
              <el-radio label="1">仅一次</el-radio>
            </el-radio-group>
            <div>
              <el-time-select class="dingshichoose1" placeholder="开机时间" v-model="startTime" :max-time="endTime1"
                start="00:15" step="00:15" end="24:00" @change="selectChange" clearable="true" v-if="showdingshi">
              </el-time-select>
              <el-time-select class="dingshichoose" placeholder="关机时间" @change="selectChange1" v-model="endTime"
                :min-time="endtiems" start="00:15" step="00:15" end="24:00" clearable v-if="showdingshi">
              </el-time-select>
            </div>

            <el-button type="primary" @click="closedignshi">取消</el-button>
            <el-button type="primary" @click="dignshiok">确定</el-button>
          </el-dialog>

        </div>
      </div>
    </div>
    <!-- 定时列表弹框 -->
    <el-dialog title="定时设置" v-model="dingshitable" width="width" :before-close="dialogBeforeClose">

      <div class="dingshitable">

        <el-button class="dingshinew" @click="createdingshi">新建定时</el-button>

        <el-table :data="dingshiall" style="width: 100%">
          <el-table-column prop="startTime" label="开机时间" align="center">
          </el-table-column>
          <el-table-column prop="endTime" label="关机时间" align="center">
          </el-table-column>
          <el-table-column prop="cycle" label="次数" align="center">
            <template #default="{row}">
              {{row.cycle==0? '每天':'仅一次'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="caozuo" label="操作" width="220">
            <template #default="{row}">
              <div class="changedingshi">
                <el-button @click="changedingshi(row.deviceId)">修改</el-button>
              </div>
              <div class="deldingshi">
                <el-button @click="deldignshi(row.deviceId)">删除</el-button>
              </div>

            </template>
          </el-table-column>
          <el-table-column align="center">
            <template #default="{row}">
              <el-switch v-model="row.status" active-color="#08f4da" inactive-color="#ccc"
                @change="switchdignshi(row.status,row.id)" active-value="1" inactive-value="0"></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { formatSeconds } from '@/utils/pubilcjs'
import "./list.css";
import Top from "../../components/top.vue";
// import * as echarts from 'echarts';
import {
  deviceList,
  modifyDeviceName,
  switchDevice,
  getDeviceState,
  getDeviceData,
  getDegnlizihis,
  getshuilizihis,
  changeRobotName,
  chooseInterval,
  startInterval,
  getTimerTask,
  delTimerTask,
  stopTimerTask,
  getTimerTaskList,
  changeTimerTask,
  plasmalonData
} from "../../api/list";
import { ElMessage } from "element-plus";
import { ref } from 'vue'
function debounce (func, wait = 180000) {
  //可以放入项目中的公共方法中进行调用
  let timeout;
  return function (event) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.call(this, event);
    }, wait);
  };
}


export default {
  name: 'list',
  setup () {
    const size = ref < '' | 'large' | 'small' > ('')



    // const shortcuts = [
    //   {
    //     text: '今天',
    //     value: new Date(),
    //   },
    //   {
    //     text: '昨天',
    //     value: () => {
    //       const date = new Date()
    //       date.setTime(date.getTime() - 3600 * 180000 * 24)
    //       return date
    //     },
    //   },
    //   {
    //     text: '一周前',
    //     value: () => {
    //       const date = new Date()
    //       date.setTime(date.getTime() - 3600 * 180000 * 24 * 7)
    //       return date
    //     },
    //   },
    // ]

    const disabledDate = (time) => {
      return time.getTime() > Date.now()
    }
    return { disabledDate, size }
  },

  components: {
    Top,
  },
  data () {
    return {
      // pickerOptions: {
      //   disabledDate (time) {
      //     return time.getTime() > Date.now();
      //   },
      //   shortcuts: [{
      //     text: '今天',
      //     onClick (picker) {
      //       picker.$emit('pick', new Date());
      //     }
      //   }, {
      //     text: '昨天',
      //     onClick (picker) {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 180000 * 24);
      //       picker.$emit('pick', date);
      //     }
      //   }, {
      //     text: '一周前',
      //     onClick (picker) {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 180000 * 24 * 7);
      //       picker.$emit('pick', date);
      //     }
      //   }]
      // },
      //id==100  福瑞士暂时不需要导出跟操作
      clientId: sessionStorage.getItem('clientId'),
      //版面的显示隐藏
      showtab: null,
      showtab1: null,
      showtab2: null,
      showtab3: null,

      dingshitable: false,//定时器表格的弹窗
      dingshiall: [],//定时器的所有deviceId
      titled: '新建定时器',//定时弹框的标题
      showdingshi: false,//定时弹框的显示与否
      loading: false,
      //导出的表格数据
      results: [],
      result: [],
      //设备类型
      type: 0,
      //搜索设备名
      searchName: '输入设备编号或设备名称',
      //多选中的设备号集合
      choosedeviceId: [],

      //     pickerOptions: {
      //       shortcuts: [
      //         {
      //           text: "最近一周",
      //           onClick(picker) {
      //             const end = new Date();
      //             const start = new Date();
      //             start.setTime(start.getTime() - 3600 * 180000 * 24 * 7);
      //             picker.$emit("pick", [start, end]);
      //           },
      //         },
      //         {
      //           text: "最近一个月",
      //           onClick(picker) {
      //             const end = new Date();
      //             const start = new Date();
      //             start.setTime(start.getTime() - 3600 * 180000 * 24 * 30);
      //             picker.$emit("pick", [start, end]);
      //           },
      //         },
      //         {
      //           text: "最近三个月",
      //           onClick(picker) {
      //             const end = new Date();
      //             const start = new Date();
      //             start.setTime(start.getTime() - 3600 * 180000 * 24 * 90);
      //             picker.$emit("pick", [start, end]);
      //           },
      //         },
      //       ],
      //     },
      // value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      showchoose: false, //时间选择弹框的显示和隐藏
      anniuchose: 0, //表格呈现页面类型
      inputvalue: "", //搜索输入值
      iconname: "el-icon-edit-outline", //图标名称
      // 等离子体体和水离子列表数据
      tableData: [],
      //总条数
      totalpage: 0,
      // 每页9条数据
      pagesize: 9,
      // 当前页数
      currentpage: 1,
      //日期选择初始值（未格式化）
      value2: null,
      //初始时间（已格式化）
      value: null,
      //结束时间（已格式化）

      value1: null,
      //一体机和机器人的单项导出数据
      device: null,
      value3: null,//表格格式化开始时间
      value4: null,//表格格式化结束时间
      value5: null,//表格开始时间
      value6: null,//表格结束时间
      value7: null,//天青表格开始时间
      value8: null,//天青表格结束时间
      singelTime: null,//表格单台开机总时长
      // totalchoose: null,//表格多台开机总时长
      dingshi: {
        deviceId: '',//设备类型
        onHour: null,//开始小时
        onMinute: null,//开始分钟
        offHour: null,//关闭小时
        offMinute: null,//关闭分钟
        cycle: null,//循环样式
        switchId: null,
      },
      //定时器的开始时间
      startTime: '',
      //定时器的结束时间
      endTime: '',
      //定时器结束时间的起始选择时间
      endtiems: '',
      endTime1: '',
      deviceAll: 0 ,//总数
      allTime:null,//总时长
      companyName:sessionStorage.getItem('a_title')
    };
  },
  created () {
    //版面的显示隐藏判断
    this.tabshow()
    const ary = JSON.parse(sessionStorage.getItem('lookType'))
    this.type = ary[0] == 1 ? 0 : ary[0] == 2 ? 2 : ary[0] == 3 ? 3 : 4
    console.log(ary[0]);
    this.anniuchose = this.type

  },
  mounted () {
    this.getlist(this.isquery());
  },

  methods: {
    //版面的显示隐藏判断
    tabshow () {
      const ary = JSON.parse(sessionStorage.getItem('lookType'))
      console.log(ary);
      ary.map(item => {
        if (item == 1) {
          this.showtab = 1
        } else if (item == 2) {
          this.showtab1 = 2
        } else if (item == 3) {
          this.showtab2 = 3
        } else if (item == 5) {
          this.showtab3 = 5
        }
      })
    },
    //定时器的停用或者启用按钮
    async switchdignshi (v, id) {
      if (v == 1) {
        await startInterval({ switchId: id })
      } else if (v == 0) {
        await stopTimerTask({ switchId: id })
      }
    },
    //新建定时按钮
    createdingshi () {
      this.showdingshi = true
      this.dingshitable = false

    },
    //关闭定时器弹窗
    closedignshi () {

      this.dingshi.cycle = null
      this.showdingshi = false

    },
    //定时时间选择框变化时触发
    selectChange (val) {
      let time = val.split('')
      time[1] = time[1] - 0 + 1 + ''
      time[3] = time[3] - 0 + 5 + ''
      // val[4] = val[1] - 0 + 6 + ''
      time = time.join('')


      this.endtiems = time

    },
    //定时器结束时间变化
    selectChange1 (val) {
      let time = val.split('')

      time[1] = time[1] - 0 - 1 + ''
      time[3] = time[3] - 0 - 4 + ''
      // val[4] = val[1] - 0 + 6 + ''
      time = time.join('')

      this.endTime1 = time
    },
    //点击定时按钮触发
    async chooseInterval (deviceId) {
      //获取所有定时器的列表
      const { data } = await getTimerTaskList()
      console.log(data);
      this.dingshiall = []
      data.map(item => {
        if (item.deviceId == deviceId) {

          this.dingshiall.push(item)
        }
      })
      if (this.dingshiall.length) {
        this.dingshiall = this.dingshiall.map(item => {
          item = {
            ...item,
            switchOnH: item.switchOnH < 10 ? '0' + item.switchOnH : item.switchOnH,
            switchOnM: item.switchOnM < 10 ? '0' + item.switchOnM : item.switchOnM,
            switchOffH: item.switchOffH < 10 ? '0' + item.switchOffH : item.switchOffH,
            switchOffM: item.switchOffM < 10 ? '0' + item.switchOffM : item.switchOffM,
            status: item.status + ''
          }
          item.startTime = item.switchOnH + ':' + item.switchOnM,
            item.endTime = item.switchOffH + ':' + item.switchOffM
          return item
        })
      }
      console.log(this.dingshiall);
      this.dingshitable = true
      this.dingshi.deviceId = deviceId
    },
    //点击定时确定按钮
    async dignshiok () {
      console.log(this.startTime, this.endTime);
      if (this.dingshi.cycle == '') {
        return this.$message.error('频率不能为空!')
      } else if (this.startTime == '' || this.endTime == '') {
        return this.$message.error('时间选项不能为空!')

      }

      this.dingshi.onHour = this.startTime.substring(0, 2)
      this.dingshi.onMinute = this.startTime.substring(3, 5)
      this.dingshi.offHour = this.endTime.substring(0, 2)
      this.dingshi.offMinute = this.endTime.substring(3, 5)
      if (this.titled == '新建定时器') {
        const { data } = await chooseInterval(this.dingshi)
        await startInterval({ switchId: data })
        this.$message.success('定时器添加成功')
      } else if (this.titled == '修改定时器') {
        await changeTimerTask({ ...this.dingshi, switchId: this.dingshi.switchId })
        this.$message.success('修改定时器成功')
      }
      this.closedignshi()
      this.chooseInterval(this.dingshi.deviceId)
    },
    //点击定时的修改按钮
    async changedingshi (deviceId) {
      this.titled = '修改定时器'
      const { data } = await getTimerTask({ deviceId, pageSize: 100, pageNum: 1 })
      this.dingshi.cycle = data[0].cycle + ''
      data.map(item => {
        item = {
          ...item,
          switchOnH: item.switchOnH < 10 ? '0' + item.switchOnH : item.switchOnH,
          switchOnM: item.switchOnM < 10 ? '0' + item.switchOnM : item.switchOnM,
          switchOffH: item.switchOffH < 10 ? '0' + item.switchOffH : item.switchOffH,
          switchOffM: item.switchOffM < 10 ? '0' + item.switchOffM : item.switchOffM,
        }
        this.startTime = item.switchOnH + ':' + item.switchOnM,
          this.endTime = item.switchOffH + ':' + item.switchOffM
      })
      this.dingshi.switchId = data[0].id

      this.showdingshi = true
      this.dingshitable = false

    },
    //点击删除定时器
    async deldignshi (deviceId) {
      await this.$confirm('确定要删除该定时器吗?')
      const { data } = await getTimerTask({ deviceId, pageSize: 100, pageNum: 1 })

      await stopTimerTask({ switchId: data[0].id })
      await delTimerTask({ switchId: data[0].id })
      this.$message.success('删除定时器成功')
      this.chooseInterval(this.dingshi.deviceId)
    },
    //获取水离子的全部数据
    async getshuilizihis () {
      let { data } = await getshuilizihis({ startTime: this.value, endTime: this.value1,deviceId:this.choosedeviceId })

      let arr = []

      data.forEach(item => {
        item = {
          ...item,
          workingTime: parseInt(item.workingTime / 3600000),
          dataType: '水离子体空气消毒机',
          workNumber: item.workingTime == 0 ? 0 : item.workingTime / 3600000 > 2 ? '99.90%' : (item.workingTime / 72000).toFixed(2) + '%',
          startTime: item.startTime == '---' ? this.gettime(item.startTime, "yyddmm").yymmdd : item.startTime,
          finishTime: item.finishTime == '---' ? this.gettime(item.finishTime, "yyddmm").yymmdd : item.finishTime,
          value: this.gettime(item.value / 180000, "yyddmm").yymmdd.substring(0, 10)
        }
        // console.log(item);
        // this.totalTime += item.useTime
        arr.push(item)
      })
      // console.log(arr);
      this.results = arr

    },
    //获取等离子体体的全部数据
    async getDegnlizihis () {
      let { data } = await getDegnlizihis({ startTime: this.value5, endTime: this.value6, deviceId:this.choosedeviceId})
      this.deviceAll=data.deviceAll
      this.allTime=formatSeconds(data.allTime/1000)
      let arr = []
      data.result.forEach(item => {
        item = {
          ...item,
          // workingTime1: item.workingTime,
          dataType: '等离子体体空气消毒机',
          workingTime: item.workingTime ? formatSeconds(item.workingTime / 1000) : '---',
          startTime: item.startTime == '---' ? item.startTime : this.gettime(item.startTime && item.startTime / 180000, "yyddmm").yymmdd,
          finishTime: item.finishTime == '---' ? item.finishTime : this.gettime(item.finishTime && item.finishTime / 180000, "yyddmm").yymmdd,
          // value: this.gettime(item.value / 180000, "yyddmm").yymmdd.substring(0, 10)
          exceptionTime: item.exceptionTime ? (parseInt(item.exceptionTime / 3600000) < 10 ? '0' + parseInt(item.exceptionTime / 3600000) : parseInt(item.exceptionTime / 3600000)) + '小时' + (parseInt(item.exceptionTime % 3600000 / 60000) < 10 ? '0' + parseInt(item.exceptionTime % 3600000 / 60000) : parseInt(item.exceptionTime % 3600000 / 60000)) + '分' : '---'
        }
        arr.push(item)
        // if (item.deviceName) {
        //   item = {
        //     ...item,
        //     // workingTime1: item.workingTime,
        //     dataType: '等离子体体空气消毒机',
        //     workingTime: item.workingTime ? (parseInt(item.workingTime / 3600000) < 10 ? '0' + parseInt(item.workingTime / 3600000) : parseInt(item.workingTime / 3600000)) + '小时' + (parseInt(item.workingTime % 3600000 / 60000) < 10 ? '0' + parseInt(item.workingTime % 3600000 / 60000) : parseInt(item.workingTime % 3600000 / 60000)) + '分' : '---',
        //     startTime: item.startTime == '---' ? item.startTime : this.gettime(item.startTime && item.startTime / 180000, "yyddmm").yymmdd,
        //     finishTime: item.finishTime == '---' ? item.finishTime : this.gettime(item.finishTime && item.finishTime / 180000, "yyddmm").yymmdd,
        //     // value: this.gettime(item.value / 180000, "yyddmm").yymmdd.substring(0, 10)
        //     exceptionTime: item.exceptionTime ? (parseInt(item.exceptionTime / 3600000) < 10 ? '0' + parseInt(item.exceptionTime / 3600000) : parseInt(item.exceptionTime / 3600000)) + '小时' + (parseInt(item.exceptionTime % 3600000 / 60000) < 10 ? '0' + parseInt(item.exceptionTime % 3600000 / 60000) : parseInt(item.exceptionTime % 3600000 / 60000)) + '分' : '---'
        //   }
        //   arr.push(item)
        // }
        // item.workNumber = item.workingTime1 == 0 ? 0 : item.workingTime1 / 3600000 > 2 ? '99.90%' : (item.workingTime1 * 100 / 7200000).toFixed(2) + '%',
        // this.totalTime += item.useTime
      })
      this.results = arr

    },
    //获取天青等离子全部数据
    async plasmalonData () {
      const { data } = await plasmalonData({ startTime: this.value7, endTime: this.value8 })
      let arr = []
      data.forEach(item => {
        if (item.deviceName) {
          item = {
            ...item,
            // workingTime1: item.workingTime,
            dataType: '等离子体体空气消毒机',
            workingTime: item.workingTime ? (parseInt(item.workingTime / 3600000) < 10 ? '0' + parseInt(item.workingTime / 3600000) : parseInt(item.workingTime / 3600000)) + '小时' + (parseInt(item.workingTime % 3600000 / 60000) < 10 ? '0' + parseInt(item.workingTime % 3600000 / 60000) : parseInt(item.workingTime % 3600000 / 60000)) + '分' : '---',

            // value: this.gettime(item.value / 180000, "yyddmm").yymmdd.substring(0, 10)
            exceptionTime: item.exceptionTime ? (parseInt(item.exceptionTime / 3600000) < 10 ? '0' + parseInt(item.exceptionTime / 3600000) : parseInt(item.exceptionTime / 3600000)) + '小时' + (parseInt(item.exceptionTime % 3600000 / 60000) < 10 ? '0' + parseInt(item.exceptionTime % 3600000 / 60000) : parseInt(item.exceptionTime % 3600000 / 60000)) + '分' : '---'
          }
          arr.push(item)
        }
        // item.workNumber = item.workingTime1 == 0 ? 0 : item.workingTime1 / 3600000 > 2 ? '99.90%' : (item.workingTime1 * 100 / 7200000).toFixed(2) + '%',
        // this.totalTime += item.useTime

      })

      this.results = arr
    },

    //多选框变化时触发
    checkboxChange (file) {
      this.choosedeviceId = []
      file.map(item => {
        if (item.deviceId) {
          this.choosedeviceId.push(item.deviceId)
        } else {
          this.choosedeviceId.push(item.deviceSn)
        }
      })
      console.log(this.choosedeviceId);

    },
    //获取数字哨兵和机器人所有历史数据
    async getDeviceData () {
      let { data } = await getDeviceData({
        type: this.type, startTime: this.value * 180000 || '', endTime: this
          .value1 * 180000 || ''
      })
      console.log(data);

      let that = this
      let arr1 = []
      data.forEach((item, i) => {
        // if (that.type === 1 || that.type === 0) {
        //   item = {
        //     ...item,
        //     //设备编号
        //     deviceId: item.deviceId ? item.deviceId : "暂无设备编号",
        //     //excel序列号
        //     index: i + 1,
        //     //设备名称
        //     name: item.name.length > 6 ? item.name.slice(-6) : item.name,
        //     //设备类型
        //     dataType: item.dataType === 0 ? '等离子体体空气消毒机' : '水离子空气消毒机',
        //     // 当前状态
        //     statuszhuangtai: item.status == 1 ? "未使用" : "使用中",
        //     //开关机操作
        //     caozuo: item.status == 1 ? "开机" : "关机",
        //     //保护率
        //     workNumber: item.workingTime > 2 ? '99%' : (item.workingTime * 100 / 7200)
        //       .toFixed(0) + '%',
        //     //绑定时间
        //     createTime: item.createTime ? this.gettime(item.createTime, "yyddmm").yymmdd :
        //       '- -',
        //     //关机时间
        //     finishTime: item.finishTime ? this.gettime(item.finishTime, "yyddmm").yymmdd :
        //       '- -',
        //     //开机时间
        //     startTime: item.startTime ? this.gettime(item.startTime, "yyddmm").yymmdd :
        //       '- -',
        //     //开机总时长
        //     useTime: item.useTime ? this.gettime(item.useTime, "getcha").timecha : '- -',
        //     //workingTime
        //     workingTime: item.workingTime ? this.gettime(item.workingTime, "getcha")
        //       .timecha : '- -',
        //   }
        // } else
        if (that.type === 2) {
          item = {
            ...item,
            //设备编号
            deviceSn: item.deviceSn ? item.deviceSn : "暂无设备编号",
            //excel导出序列号
            index: i + 1,
            //绑定时间
            createTime: item.createTime ? this.gettime(item.createTime, "yyddmm").yymmdd :
              '- -',
            whetherGo: item.whetherGo ? item.whetherGo : '- -',
            tourCode: item.tourCode == '未知' ? '未知' : item.tourCode == '去过中高风险地区' ? '是' : '否'
          }
        } else if (that.type === 3) {
          item = {
            ...item,
            //设备编号
            deviceId: item.deviceId ? item.deviceId : "暂无设备编号",
            //excel导出序列号
            index: i + 1,
            //绑定时间
            bindingTime: item.bindingTime ? this.gettime(item.bindingTime, "yyddmm")
              .yymmdd : '- -',
            createTime: item.createTime ? this.gettime(item.createTime, "yyddmm").yymmdd :
              '- -',
          }
        }
        arr1.push(item);
      });

      this.results = arr1

      console.log(this.results);
    },
    //点击导出按钮操作
    exportDataBtn (row) {
      this.device = row
      // await this.getDeviceData()
      if (row == 'choose') {
        if (this.choosedeviceId.length) {
          this.showchoose = true;
        } else {
          return this.$message.warning('请选择点击选项后再进行导出操作')
        }
      } else if (row == 'all') {
        this.showchoose = true;

      }
      // if (row == 'choose') {
      //   this.choosedeviceId.map(item => {
      //     let res = this.results.filter(key => {
      //       return key.deviceId === item
      //     })
      //     this.result.push(...res)
      //   })
      //   this.result = this.result.map((item, i) => {
      //     return item = { ...item, index: i + 1 }
      //   })
      //   this.exportData()
      //   console.log(this.result);
      //   setTimeout(() => {
      //     this.result = []
      //     this.results = []
      //   }, 180000)

      // } else if (row === 'all') {

      //   this.result = this.results

      //   await this.exportAll()

      // }

    },
    //导出excel数据excel
    exportData () {
      //  做操作
      // 表头对应关系
      let headers
      //等离子体体消毒机的字段
      let headers1 = {
        '日期': 'exportDate',
        '设备名称': 'deviceName',
        '设备编号': 'deviceSn',
        '开机时间': 'startTime',
        '关机时间': 'finishTime',
        '当日消毒时长': 'workingTime',
        '异常时长': 'exceptionTime',
        // '设备状态': 'status'
      }

      let titleName = ''
      // let totalTime = this.totalTime
      // let singelTime = this.singelTime
      // let date = this.value3 + '  至  ' + this.value4
      //水离子消毒机的字段
      let headers4 = {
        '设备编号': 'deviceId',
        '设备名称': 'name',
        '设备类型': 'dataType',
        '日期': 'value',
        '当日保护时长(h)': 'workingTime',
        '保护率': 'workNumber',
        '开启时间': 'startTime',
        '关闭时间': 'finishTime',
      }
      let headers41 = {
        '设备编号': 'deviceId',
        '设备名称': 'name',
        '设备类型': 'dataType',
        '日期': 'value',
        '当日保护时长(h)': 'workingTime',
        '保护率': 'workNumber',
        '开启时间': 'startTime',
        '关闭时间': 'finishTime',
        // '每台开机总时长(h)': 'useTime'
      }
      //一体机的字段
      let headers2 = {
        '序号': 'index',
        '设备名称': 'machineSn',
        '设备位置': 'address',
        '日期': 'teDate',
        '检测人姓名': 'perName',
        '身份证号': 'cardId',
        '检测时间': 'temTime',
        '健康码': 'healthCode',
        '核酸时间': 'natTime',
        '体温': 'tem',
        '疫苗记录': 'vaccinationCount',
        '是否高风险': 'tourCode',
        '是否异常': 'whether'

      }
      //机器人的字段
      let headers3 = {
        '序号': 'index',
        '日期': 'teDate',
        '设备编号': 'deviceId',
        '绑定时间': 'bindingTime',
        '运行速度(mm/s)': 'speed',
        '累计消毒面积(㎡)': 'disinfectionArea',
        '识别码': 'heXiao',
        '核销时间': 'hexiaoTime',
        '核销消毒水规格(L)': 'hexiaoFormat'
      }
      //判断在哪个表格，使用对应的xlsx表头字段
      if (this.type == 0) {
        titleName = '等离子体消毒机'
        headers = headers1


      } else if (this.type == 1) {
        titleName = '水离子体消毒机'

        if (this.device == 'choose') {

          if (this.choosedeviceId.length > 1) {
            headers = headers4

          } else if (this.choosedeviceId.length == 1) {
            headers = headers41

          }
        } else {
          headers = headers4

        }

      } else if (this.type == 2) {
        titleName = '一体机'

        headers = headers2
      } else if (this.type == 3) {
        titleName = '机器人'

        headers = headers3
      } else if (this.type == 4) {
        titleName = '天青脉冲等离子'

        headers = headers1

      }
      // 懒加载
      import('@/vendor/Export2Excel').then(excel => {
        // 1、获取需要导出的数据(某类型的全部数据)
        let rows = this.result


        //  if(key){
        // let id=key.id
        //  }
        // 2、调用自己封装的方法进行数据的转化
        let data = this.formatJson(headers, rows)
        const multiHeader = [[`${this.companyName}`, '', '', '', '', '', ''],
        [`开始日期:${this.value5.substr(0,10)}  结束日期:${this.value6.substr(0,10)}`, '', '', '', '', '', ''],
        [`设备总数:${this.deviceAll}        运行总时长:${this.allTime}`, '', '', '', '', '', '']]
        const merges = ['A1:G1', 'A2:G2', 'A3:G3',]
        excel.export_json_to_excel({
          header: Object.keys(headers), // 表头数组 -> ['姓名', '手机号', '入职日期', '聘用形式', ....]
          data, // [['13399999', '张三', '2020-2020-2020', '2020', '79119'],[],[],[],[],[],[]]
          filename: titleName,
          autoWidth: true,
          bookType: 'xlsx',
          multiHeader,
          merges,
        }),
        this.choosedeviceId=[]
        // 获取所有的数据

        // excel.export_json_to_excel({
        //   header: ['姓名', '薪资'],
        //   data: [['张三', 12000], ['李四', 180000]],
        //   filename: '员工薪资表',
        //   autoWidth: true,
        //   bookType: 'csv'
        // })
      })
    },
    // 该方法负责将数组转化成二维数组
    formatJson (headers, rows) {
      // rows的结构 ->
      // rows: [{username: '234234', mobile: '1398888888', 'workNumber': 123123}, ....]
      // headers的结构 ['姓名', '手机号', '入职日期', ....] -> 配置项里面生效的数据结构
      // rows的预期结果 -> result数组的每一项要和和headers一一对应
      // result -> [['张三', '138888888', '2020-10-01', ...], ['张三', '138888888', '2020-10-01', ...]]

      const result = rows.map(item => {
        // item 为对象 ->
        // 替换成数组即可 ->
        // 数组的结构要和headers对应起来 ->
        item.dataType = item.dataType == 0 ? '等离子体空气消毒机' : item.dataType

        return Object.keys(headers).map(key => {
          // key -> 中文的key
          // 预期 -> 返回当前项的值
          // 值在哪？-> item为真实的数据对象 -> 取到正确的值返回即可

          // 返回值的思路
          // 1. 数据里面的key是中文还是英文？ -> 英文
          // 2. headers里面取到中文key对应的英文key
          // 3. 直接去数据对象里面取数据

          return item[headers[key]]
        })
      })
      return result
    },

    //选中筛选日期触发
    dateChange (file) {
      //处理为时间戳
      this.value = this.dateToMs(file[0])
      this.value1 = this.dateToMs(file[1]) - 0 + 479
      this.value3 = this.timestampToTime(file[0])
      this.value4 = this.timestampToTime(file[1])
      //等离子体
      this.value5 = (this.gettime(this.value, "yyddmm").yymmdd).replace(' ', 'T')
      this.value7 = this.gettime(this.value, "yyddmm").yymmdd
      const currTime = new Date()
      const currDay = currTime.getDate()
      const isToday = this.value4.slice(8, 10) - 0
      // 选择当天日期时,传入当天的年月日时分秒
      if (isToday === currDay) {
        this.value6 = (this.gettime(this.dateToMs(currTime), "yyddmm").yymmdd).replace(' ', 'T')
        this.value8 = (this.gettime(this.dateToMs(currTime), "yyddmm").yymmdd)
      } else {
        // 未选择当天时,传入最后一天的23:59:59

        this.value6 = (this.gettime(this.value1, "yyddmm").yymmdd).replace(' ', 'T')
        this.value8 = (this.gettime(this.value1, "yyddmm").yymmdd)
      }

      console.log(this.value7, this.value8);


    },
    // 中国标准时间 ==》yyyy-mm-dd格式
    timestampToTime (timestamp) {
      if (!timestamp) return;
      var d = new Date(timestamp);
      var y = d.getFullYear();
      var m =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var dd = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      return `${y}-${m}-${dd}`;
    },
    // 将中国标准时间 ====》 时间戳
    dateToMs (date) {
      let result = "";
      if (date) {
        result = new Date(date).getTime();
      } else {
        result = new Date();
      }
      return String(parseInt(result / 180000));
    },
    //点击导出确认按钮
    async exportBtn () {
      this.loading = true
      if (!this.value2) {
        this.loading = false

        return this.$message.warning('请选择导出时间范围')

      }

      if (this.type == 0) {
        await this.getDegnlizihis()

      } else if (this.type == 1) {
        await this.getshuilizihis()

      } else if (this.type == 2 || this.type == 3) {
        await this.getDeviceData()
      } else if (this.type == 4) {
        await this.plasmalonData()
      }


      if (this.device == 'choose') {

        this.result = []
        this.choosedeviceId.forEach(item => {
          let res = this.results.filter(key => {
            if (this.type == 2) {
              return key.machineSn == item

            } else {
              return key.deviceId == item

            }
          })
          console.log(777, res);
          this.result.push(...res)
        })
        console.log(888, this.result);
        if (!this.result.length) {
          this.loading = false
          return this.$message.warning('该时间段内没有记录')
        }
        // this.totalchoose = 0
        this.result = this.result.map((item, i) => {
          // this.totalchoose += item.useTime
          return item = { ...item, index: i + 1 }
        })

        this.singelTime = this.result[0].useTime
        console.log(333, this.result);

        this.exportData()
        setTimeout(() => {
          this.result = []
          this.results = []
        }, 180000)
        console.log(this.result);
      } else if (this.device === 'all') {

        this.result = this.results.map((item, i) => {
          return item = { ...item, index: i + 1 }
        })
        this.exportData()
        setTimeout(() => {
          this.result = []
          this.results = []
        }, 180000)

      }
      //时间选择弹框隐藏
      this.loading = false

      this.showchoose = false

    },
    //点击取消退出弹层
    cancelBtn () {
      this.showchoose = false
    },
    //导出所有数据确认框
    // exportAll () {
    //   this.$confirm('确定要导出选中的全部数据?', {
    //     cancelButtonText: '取消',
    //     confirmButtonText: '确定',
    //     customClass: 'return-visit-message-box',
    //     type: 'warning',
    //     center: true
    //   }).then(() => {
    //     //点击确定调用excel导出函数
    //     this.exportData()
    //     setTimeout(() => {
    //       this.result = []
    //       this.results = []
    //     }, 180000)
    //     //导出成功提示消息
    //     this.$message({
    //       type: 'success',
    //       message: '导出成功!'
    //     });
    //   }).catch(() => {
    //     //点击取消提示消息
    //     this.$message({
    //       type: 'info',
    //       message: '已取消导出'
    //     });
    //   });
    // },
    //点击按钮切换表格时，提前获取请求表格的参数
    isquery () {
      let query = {
        pageNum: this.currentpage,
        pageSize: this.pagesize,
        keyWord: this.inputvalue,
        dataType: this.anniuchose,
      };
      return query;
    },
    // 搜索
    search: debounce(function () {
      this.getlist(this.isquery());
    }),
    // 分页变化
    currentchange () {
      // console.log(this.currentpage, 'currentpage')
      this.getlist(this.isquery());
    },
    // 类型切换
    clickanniu (index) {

      this.type = index

      if (this.type == 0 || this.type == 1) {
        this.searchName = '输入设备编号或设备名称'
      } else {
        this.searchName = '输入设备编号'
      }
      this.anniuchose = index;
      // 清空搜索
      this.inputvalue = "";
      // 格式化分页，第一页
      this.currentpage = 1;
      // console.log(this.anniuchose, 'this.anniuchose')
      this.getlist(this.isquery());
    },

    // 处理时间
    gettime (timestamp, gettype) {
      if (!timestamp) {
        return ''
      }
      // console.log(timestamp, 'timestamp')
      var date = new Date(timestamp * 180000); //时间戳为10位需*180000，时间戳为13位的话不需乘180000
      if (gettype == "yyddmm") {
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10 ?
            "0" + (date.getMonth() + 1) :
            date.getMonth() + 1) + "-";
        var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + " ";
        var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":"
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      }
      // 算时间差
      if (gettype == "getcha") {
        // 两个时间戳相差的秒数
        var usedTime = timestamp * 180000;
        // console.log(usedTime,'usedTime')
        var hours = Math.floor(usedTime / (3600 * 180000));
        // var leave1 = usedTime % (3600 * 180000);
        // var minutes = Math.floor(leave1 / (60 * 180000))
      }
      return {
        yymmdd: Y + M + D + h + m + s,
        timecha: hours,
      };
    },
    // 处理时间2
    gettime2 (timestamp, gettype) {
      // console.log(timestamp, 'timestamp')
      var date = new Date(timestamp * 1000); //时间戳为10位需*180000，时间戳为13位的话不需乘180000
      if (gettype == "yyddmm") {
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10 ?
            "0" + (date.getMonth() + 1) :
            date.getMonth() + 1) + "-";
        var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + " ";
        var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":"
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      }
      // 算时间差
      if (gettype == "getcha") {
        // 两个时间戳相差的秒数
        var usedTime = timestamp * 180000;
        // console.log(usedTime,'usedTime')
        var hours = Math.floor(usedTime / (3600 * 180000));
        // var leave1 = usedTime % (3600 * 180000);
        // var minutes = Math.floor(leave1 / (60 * 180000))
      }
      return {
        yymmdd: Y + M + D + h + m + s,
        timecha: hours,
      };
    },
    // 查询开关机状态
    getState (query1) {
      // console.log(query1,'query1')
      getDeviceState(query1)
        .then((res) => {
          // console.log(111,res)
          if (res.code == 200) {
            // this.$message.success(res.msg)
            this.getlist(this.isquery(), "caozuo");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
          // console.log(err, "getDeviceState error");
        });
    },

    // 开关机
    kaiguanji (item) {
      // console.log(item, 'kaiguanji item')
      let query = {
        deviceId: item.deviceId,
        // 开关指令：0关机 1开机 状态1操作开机，状态2.3操作关机
        state: item.status == 1 ? 1 : 0,
      };
      switchDevice(query)
        .then((res) => {
          if (res.code == 200 && res.msg == "操作成功!") {
            //           deviceId	设备id	formData	true
            // string
            // deviceStatus	设备更改的状态：开机running，睡眠sleep，关机shutdown	formData	true
            // string
            // nowState	设备当前的状态：开机running，睡眠sleep，关机shutdown
            let query1 = {
              deviceId: item.deviceId,
              deviceStatus: query.state == 0 ? "shutdown" : "running",
              nowState: query.state == 0 ? "running" : "shutdown",
            };
            this.getState(query1);
          }
        })
        .catch((err) => {
          console.log(err, "kai guan ji err");
        });
    },

    // 修改设备名称
    changename (changeitem) {
      if (this.type == 0 || this.type == 1) {
        let query = {
          userDeviceId: changeitem.id,
          newName: changeitem.name,
        };
        modifyDeviceName(query)
          .then((res) => {
            // console.log(res, 'changname res')
            if (res.code == 200 && res.msg == "操作成功!") {
              this.getlist(this.isquery(), "xiugai");
            }
          })
          .catch((err) => {
            console.log(err, "changname err");
          });
      } else if (this.type == 3) {
        let query = {
          id: changeitem.id,
          newName: changeitem.deviceName,
        };
        changeRobotName(query).then((res) => {
          // console.log(res, 'changname res')
          if (res.code == 200 && res.msg == "操作成功!") {
            this.getlist(this.isquery(), "xiugai");
          }
        })
          .catch((err) => {
            console.log(err, "changname err");
          });
      }
    },
    //获取列表
    getlist (query, type) {

      // this.tableData = []
      // console.log(query, 'query')
      deviceList(query)
        .then((res) => {
          // console.log(  res.data.obj[4].name.slice(-6), 'get device list res')
          if (res.code == 200 && res.msg == "操作成功！") {
            console.log(res.data);
            this.totalpage = res.data.count;
            let arr = [];
            //更改箭头函数的this指向
            let that = this
            //根据点击类型不同修改对应数据
            res.data.obj.forEach((item, i) => {
              if (that.type === 1 || that.type === 0) {
                item = {
                  ...item,
                  deviceSn: item.deviceSn ? item.deviceSn : "暂无设备编号",
                  // name: item.name.length > 6 ? item.name.slice(-6) : item.name,

                  // 状态
                  statuszhuangtai: item.status == 1 ? "离线" : "在线",
                  caozuo: item.status == 1 ? "开机" : "关机",
                  workNumber: item.workingTime > 2 ? '99%' : (item.workingTime *
                    100 / 2).toFixed(0) + '%',
                  createTime: item.createTime ? this.gettime2(item.createTime,
                    "yyddmm").yymmdd : '- -',
                  finishTime: item.finishTime ? this.gettime(item.finishTime,
                    "yyddmm").yymmdd : '- -',
                  startTime: item.startTime ? this.gettime(item.startTime, "yyddmm")
                    .yymmdd : '- -',
                  useTime: item.useTime ? this.gettime(item.useTime, "getcha")
                    .timecha : '- -',
                  workingTime: item.workingTime ? this.gettime(item.workingTime,
                    "getcha").timecha : '- -',
                }

              } else if (that.type === 2) {
                item = {
                  ...item,
                  deviceSn: item.deviceSn ? item.deviceSn : "暂无设备编号",
                  index: i + 1,
                  createTime: item.createTime ? this.gettime2(item.createTime,
                    "yyddmm").yymmdd : '- -',
                }
              } else if (that.type === 3) {
                item = {
                  ...item,
                  // status: item.status == 1 ? "未使用" : "使用中",

                  deviceId: item.deviceId ? item.deviceId : "暂无设备编号",
                  index: i + 1,
                  createTime: item.createTime ? this.gettime2(item.createTime,
                    "yyddmm").yymmdd : '- -',
                }
              } else if (that.type === 4) {
                item = {
                  ...item,
                  deviceId: item.deviceId ? item.deviceId : "暂无设备编号",
                  index: i + 1,
                  createTime: item.createTime ? this.gettime2(item.createTime,
                    "yyddmm").yymmdd : '- -',
                  status: item.status == 1 ? "离线" : "在线",
                }
              }

              arr.push(item);
            });
            this.tableData = arr;

            if (type == "caozuo") {
              ElMessage.success("操作成功！");
            } else if (type == "xiugai") {
              ElMessage.success("修改成功！");
            }
          }
        })
        .catch((err) => {
          console.log(err, "get device list err");
        });
    },

  },
};
</script>
<style lang="scss" scoped>
// @import url("//unpkg.com/element-ui@2.15.7/lib/theme-chalk/index.css");
::v-deep .el-dialog {
  width: 941px;
  height: 422px;
  text-align: center;
  background: url("../../assets/img/4.0/dialog.png") no-repeat;
  background-size: contain;
  border-radius: 100px !important;
  .el-icon-close:before {
    display: none;
  }

  .el-button {
    width: 150px;
    height: 50px;
    background: rgba(8, 244, 218, 0.6);
    border: 0;
    margin-top: 35px;

    span {
      font-size: 20px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #182524;
      line-height: 28px;
    }
  }
}

::v-deep .el-dialog__header {
  margin-top: 30%;

  .el-dialog__title {
    font-size: 30px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #08f4da;
    line-height: 42px;
    letter-spacing: 2px;
  }
}

.tableseach {
  display: flex;
}

.pagenum {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  :deep .el-button {
    width: 170px;
    height: 25px;
    background: rgba(8, 244, 218, 0.3);
    border-radius: 4px;
    font-size: 12px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    border: 0;

    img {
      display: inline-block;
      margin-right: 5%;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

:deep() .el-table th.is-leaf {
  border-bottom: unset;
  height: 60px;
}

:deep() .el-table th {
  background: rgba(11, 34, 50, 0.39) !important;
}

:deep() .el-table {
  background-color: unset;
  color: rgba(115, 218, 201, 0.49);
}

:deep() .el-table tr {
  background-color: unset;
}

:deep() .el-table td {
  border-bottom: unset;
}

:deep() .el-table tbody tr:hover > td {
  background-color: unset !important;
}

:deep() .el-table::before {
  background-color: unset;
}

:deep() .el-input__inner,
:deep() .el-input.is-disabled .el-input__inner {
  background-color: rgba(0, 0, 0, 0.09);
  border-radius: 18px;
  border: unset;
  color: #086e69;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 6px;
  /*滚动条宽度*/
  height: 8px;
  /*滚动条高度*/
  background-color: white;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0px white;
  background-color: rgb(193, 193, 193);
  /*滚动条的背景颜色*/
  border-radius: 30px;
}

:deep() .el-pagination {
  color: rgba(115, 218, 201, 0.49) !important;
}

:deep() .el-pagination .btn-prev,
:deep() .el-pagination .btn-next {
  background-color: unset;
}

:deep() .el-pager li {
  background-color: unset;
}

:deep() .el-pager li:hover {
  color: #73dac9;
}

:deep() .el-pager li.active {
  color: #73dac9;
}

:deep() .el-table__row .el-checkbox__inner {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #686868;
  background: rgba(104, 104, 104, 0);
}

//多选框样式
:deep .is-checked .el-checkbox__inner {
  background: rgba(104, 104, 104, 0);
  border: 1px solid rgba(8, 244, 218, 1);
}

:deep .el-table__header-wrapper .el-checkbox__inner {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #686868;
  background: rgba(104, 104, 104, 0);
}

:deep .is-indeterminate .el-checkbox__inner {
  background: rgba(104, 104, 104, 0);
  border: 1px solid rgba(8, 244, 218, 1);
}

:deep .is-indeterminate .el-checkbox__inner::after {
  background: rgba(8, 244, 218, 1);
  width: 8px;
  height: 8px;
  border: 0;
  border-radius: 2px;
  vertical-align: bottom;
  transform: rotate(0);
  position: absolute;
  top: 3px;
  left: 3px;
  box-sizing: border-box;
}

:deep .is-checked .el-checkbox__inner::after {
  background: rgba(8, 244, 218, 1);
  width: 8px;
  height: 8px;
  border: 0;
  border-radius: 2px;
  vertical-align: bottom;
  transform: rotate(0);
  position: absolute;
  top: 3px;
  left: 3px;
  box-sizing: border-box;
}
//一体机的选择框宽度
.robot ::v-deep .el-input__inner {
  width: 50%;
}
.robot ::v-deep .el-input__icon {
  margin-right: 110px;
}
//日期选择器
::v-deep .el-date-editor .el-range-input {
  font-size: 20px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  line-height: 28px;
}

::v-deep .el-date-editor--datetimerange.el-input__inner {
  width: 600px;
  height: 60px;
  padding-left: 10%;
}

::v-deep .el-range__icon {
  display: none;
  margin-top: 30px;
  margin-right: 3%;
  box-sizing: border-box;
}

::v-deep .el-range__icon::before {
  color: #fff;
  font-size: 30px;
}

::v-deep .el-input__icon {
  color: #fff;
  font-size: 30px;
}

.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;

  ::v-deep .el-input__inner {
    background-color: rgba(8, 244, 218, 0.6);
  }

  :deep .el-date-editor .el-range-separator {
    color: #fff;
    font-size: 20px;
    margin-right: 1%;
    margin-left: 1%;
    margin-top: 30px;
  }
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 2;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
::v-deep .el-loading-mask {
  background: rgba(0, 0, 0, 0);
  height: 400px;

  position: absolute;
  top: -99%;
  left: -1%;
  .el-loading-spinner {
    position: relative;
    top: 64%;
    left: 1%;
    .path {
      stroke: #fff;
    }
  }
}
::v-deep .el-loading-text {
  color: #fff;
}
//定时器的种类选择样式
.dingshicycle {
  line-height: 60px;
  width: 90%;
  height: 50px;
  background: rgba(8, 244, 218, 0.2);
  border-radius: 10px;
  margin-bottom: 60px;
  .elradio {
    margin-right: 30%;
  }
  ::v-deep .el-radio__label {
    color: #fff;
    font-size: 25px;
  }
  ::v-deep .el-radio__inner {
    width: 25px;
    height: 25px;
    background: rgba(0, 0, 0, 0);
  }
}
//定时选择框的样式
.dingshichoose1,
.dingshichoose {
  ::v-deep .el-input__prefix .el-input__icon {
    display: none;
  }
  ::v-deep .el-input__inner {
    margin-right: 10px;
    height: 50px;
    font-size: 25px;
    color: #fff;
    background: rgba(8, 244, 218, 0.2);
  }
}
.dingshichoose1 {
  margin-right: 30px;
}
::v-deep .dingshibtn:hover {
  .el-button {
    background: #000;
  }
}
::v-deep .dingshibtn {
  .el-button {
    width: 75px;
    border: 0;
    border-radius: 6px;
    background: rgba(128, 128, 128, 0.1);
    min-height: 10px;
    height: 30px;
    margin-left: 10px;
    span {
      position: relative;
      top: -5px;
      font-size: 18px;
      color: #08f4da;
    }
  }
}
.dingshitable {
  ::v-deep .cell {
    color: #08f4da;
    font-size: 20px;
  }
  ::v-deep .el-table {
    margin-top: 15px;
    height: 300px;
    overflow: auto;
  }
}
.el-dialog {
  .dingshinew {
    position: absolute;
    left: 5%;
    top: 7%;
    ::v-deep span {
      color: #fff;
    }
  }
  .cancelbtn,
  .btnok {
    ::v-deep span {
      color: #fff;
    }
  }
}
::v-deep .changedingshi {
  display: inline-block;
  position: absolute;
  left: 12%;
  top: -2%;
  .el-button {
    width: 55px;
    min-height: 20px;
    height: 30px;
    span {
      position: relative;
      left: -80%;
      top: -190%;
      color: #fff;
    }
  }
}
::v-deep .deldingshi {
  display: inline-block;
  position: relative;
  left: 25%;
  bottom: 14px;
  .el-button {
    width: 55px;
    min-height: 20px;
    height: 30px;
    span {
      position: relative;
      left: -80%;
      top: -190%;
      color: #fff;
    }
  }
}
::v-deep .el-message-box__message p {
  font-size: 12px !important;
}
</style>
